<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="经典案例" @moTitltFn="navPanel"></MoTitle>
    <div class="motypeContainer">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">经典案例</h1>
      </div>

      <div class="schoolCOntent">
        <div v-for="(item,index) in schoolList" :key="index" class="schoolItem">
          <img :src="item.src" alt="" class="schoolItemImg">
          <div style="width:100%;padding: 20px;box-sizing: border-box;">
            <div style="font-size: 18px;font-weight: 600;color: #222222;line-height: 28px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);margin-bottom:10px">{{item.title}}</div>
            <div v-html="item.content" style="font-size: 14px;font-weight: 400;color: #444444;line-height: 24px;text-shadow: 0px 8px 24px rgba(0,0,0,0.15);"></div>
          </div>
        </div>
      </div>

       <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
export default {
  name: 'moTypicalcase',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      schoolList: [
        { title: '清华大学', content: '不同类型人员设置不同招聘方案，可根据申报的岗位类型判断审核节点的流转。</br> 招聘与报到入职联动，实现简历自动转入，避免信息重复填报。</br>建设人才资源池，发现高潜人才，保留关键人才。', src: require('@/assets/TypicalCase/typeCase/qh.png') },
        { title: '北京航空航天大学', content: '实现职称评审全系列网络化流程化管理，评审材料电子化，评审过程全留痕。</br>业绩数据自动抓取，评审资源智能匹配，申报条件自主摸底，提升学校各类评审工作效率。</br>基于系统强大取数和计算功能，内置报表自动填报，实现数据深度应用。', src: require('@/assets/TypicalCase/typeCase/bh.png') },
        { title: '北京师范大学', content: '统一标准，搭建规范、精准的教师信息库，实现教职工分类细化，四级分类管理。</br> 非编人员全生命周期管理，涵盖入职、调动、薪酬、离校，实现非编人力资源全场景应用。</br>落地智能薪酬体系，实现自动化薪酬核算全流程，覆盖常规工资、绩效工资、社保、公积金全场景，实现数字化薪酬管理。', src: require('@/assets/TypicalCase/typeCase/bjsf.png') },
        { title: '中国政法大学', content: '联动岗位体系，落地多套薪酬方案，高效精准算薪。</br>社保、央保自动化核算，告别繁琐计算风险。 </br>联动一体化数据，教职工历史周期薪资档案高效管理。', src: require('@/assets/TypicalCase/typeCase/zgzf.png') },
        { title: '中国科学院大学', content: '教职工信息聚合展示，信息完整留痕，实时流转，方便查询。</br>招聘业务场景化，优化用户体验，全方位人才甄选。 </br>入转调离全生命周期管理，基于时间轴可回溯、追踪教职工成长轨迹。', src: require('@/assets/TypicalCase/typeCase/zgkxy.png') },
        { title: '南开大学', content: '与全国博管办官网进行数据联动，博士后信息自动归档。</br>灵活定义博士后业务表单，满足博士后管理需求。 </br>博士后管理全过程流程驱动，实现智慧决策。', src: require('@/assets/TypicalCase/typeCase/nk.png') },
        { title: '吉林大学', content: '通过业务采集、系统同步、信息审核等多种采集校验方式，构建吉林大学统一数据标准与维度，实现数据“全”“统”“通”。</br>优化业务流程，驱动业务变革，以技术的先进性和创新性化解传统人事业务制度的局限与困境。 </br>以人为本，面向校领导、二级单位、教职工等不同角色、不同业务、不同场景，构建人性化、泛在化服务与应用。', src: require('@/assets/TypicalCase/typeCase/jldx.png') },
        { title: '东北师范大学', content: '打造一站式人力资源与管理服务平台，为“智慧校园”建设赋能。</br>出国境全生命周期业务管理，提升教职工服务满意度。 </br>互联网赋能博士后评优，掀起业务创新新篇章。', src: require('@/assets/TypicalCase/typeCase/dbsf.png') },
        { title: '山东大学', content: '一校三地，人员分类细化，通过标准规范，建立集中共享的人力资源数据平台。</br>应发展大势，将数字签名的路径向纵深不断推进。 </br>全面集成，实现系统间、组织间的数据共享，业务协同。', src: require('@/assets/TypicalCase/typeCase/sddx.png') },
        { title: '华东师范大学', content: '建立专业分类招聘门户，塑造关键性人才引进新优势。</br>评聘一体化，助力高校培植识才爱才敬才用才的沃土。 </br>非编人员全面管理，赋能员工，激活组织。', src: require('@/assets/TypicalCase/typeCase/hdsf.png') },
        { title: '中山大学', content: '建立全域教职工信息库，科学的数据采集和数据管理机制，教职工信息全方位管理。</br>打造人才标准贯穿招聘始终、招聘流程智能高效、学校品牌精益化运营的数智化招聘组织能力。 </br>业务驱动、数据驱动的闭环人力资源管理体系优化人力资源运营效率，降低人工成本。', src: require('@/assets/TypicalCase/typeCase/zsdx.png') },
        { title: '西安交通大学', content: '敏捷支持个性化招聘方案，自动化招聘协作，实现精准人才选拔。</br>梳理人力资源信息体系和流程体系，打造数字化、智能化人力资源共享平台。 </br>通过预警和待办功能，规避各种业务风险，将人力资源管理变被动为主动。', src: require('@/assets/TypicalCase/typeCase/xajd.png') },
        { title: '华中科技大学', content: '创新工具：高频次环节模块化，贴近各项评审实际。</br>重塑效率：从“零碎”到体系化，实现差异化管理和监控。 </br>服务驱动：发挥人力资源信息化在人才评审的专业价值。', src: require('@/assets/TypicalCase/typeCase/hzkj.png') },
        { title: '东北大学', content: '统一标准，搭建规范、精准的全员信息库，通过业务办理实现数据自动更新。</br>满足教职工全生命周期管理，实现业务处理的规范化和自动化。</br>实现岗变薪变业务联动，提升业务办理效率，整合薪酬福利体系。', src: require('@/assets/TypicalCase/typeCase/dbdx.png') },
        { title: '大连理工大学', content: '实现线上全流程招聘管理，提升大连理工大学工作效率，提高应聘人员服务体验。</br>建立全流程化、全网络化的同行评议机制，提升同行评议的公平公正和规范性。</br>通过人事制度的流程化、表单化、信息化，实现对人事管理业务的全方位支持。', src: require('@/assets/TypicalCase/typeCase/dllgdx.png') },
        { title: '西北工业大学', content: '借助人力资源信息化，实现学校内部信息共享、流程优化、业务联动。</br>通过系统灵活定义各类薪资标准、套账，实现在同一平台下多类人员类型的规范管理。</br>针对人才评价进行量化指标、考核评价，为人才培养与激励提供依据。', src: require('@/assets/TypicalCase/typeCase/xbgydx.png') },
        { title: '新疆大学', content: '高效落地人力规划，覆盖教职工全生命周期，助力敏捷组织人事管理。</br>极速人事流程，告别线下繁琐手续，保证业务连贯不中断。 </br>沉淀人才全景数据，实现人才立体洞察，赋能管理决策。', src: require('@/assets/TypicalCase/typeCase/xj.png') },
        { title: '西安电子科技大学', content: '灵活的算薪场景，智能公式向导，轻松设置任意复杂核算规则。</br>学院业绩成果测算，强化从战略到目标的全过程，有效落地岗位职责监控。 </br>实现后勤人力资源规范管理，助力搭建数据共享平台，打造西电信息化“和谐号”。', src: require('@/assets/TypicalCase/typeCase/xadzkj.png') },
        { title: '中南大学', content: '通过人事异动模块建设，实现教职工入转调离全生命周期管理。</br>建立合理的薪酬体系，通过汉化公式对薪酬方案进行高效便捷的设计与调整。 </br>建立领导数字看板，全面汇聚全方位信息，洞察人才，分析人才，发现人才。', src: require('@/assets/TypicalCase/typeCase/zndx.png') },
        { title: '重庆大学', content: '系统全面集成+全生命周期业务流程智能办理，实现业务和数据的互联互通。</br>绩效、津贴等薪酬自动化核算，动态监控风险早预知。 </br>核算规则灵活设定多场景覆盖，助力重大数字化薪酬管理。', src: require('@/assets/TypicalCase/typeCase/cqdx.png') },
        { title: '中国海洋大学', content: '规范标准的数据体系、流程体系、薪酬体系，推进人力资源管理精细化运营。</br>通过预警和待办事项，规避业务风险，将人力资源管理变被动为主动。</br>通过系统的全面集成，打破业务壁垒，实现数据互通，业务协同。', src: require('@/assets/TypicalCase/typeCase/zghydx.png') },
        { title: '上海外国语大学', content: '依靠业务流程，实现数据自动更新，助力领导科学决策。</br>通过系统清晰掌握人才队伍建设情况，助推人才培养。 </br>业务预警+自助提醒，推进业务顺利开展，有效降低人力资源工作风险。', src: require('@/assets/TypicalCase/typeCase/shwgy.png') },
        { title: '西北农林科技大学', content: '多层级组织架构，信息穿透，层层精确至人员信息库。</br>整合招聘流程，智能筛选简历，优化人才引进渠道。</br>优化评价方式，灵活设置定量、定性指标及评分权重，助力人才建设。', src: require('@/assets/TypicalCase/typeCase/xbnlkj.png') },
        { title: '北京科技大学', content: '高效落地人力规划，覆盖教职工全生命周期，提供互联网化体验，助力高校实现敏捷组织人事管理。</br>灵活的岗位职务体系，统一人才发展通道，多层级组织架构，有效支撑高效运营。 </br>入转调离等常见流程自助发起，告别线下繁琐手续，线上合同无纸化运营，有效降成本。', src: require('@/assets/TypicalCase/typeCase/bjkj.png') },
        { title: '中央财经大学', content: '搭建统一人事平台，灵活构建人员指标体系，实现人事数据信息共享。</br>多维度数据统计查询，实时动态掌握教职工结构分布状态。 </br>职称评审线上协同，提高申报、审核效率，改善评审体验。', src: require('@/assets/TypicalCase/typeCase/zycj.png') },
        { title: '东北林业大学', content: '一数一源，全面梳理机构与教职工信息库，为人事数据顺畅流转，高效赋能奠定基础。</br>无缝协同，以教职工为核心，不同人事应用场景互联互融，打造智慧人事业务大平台。</br>数据驱动，探索科学管理与决策，为中高层提供“一站式”决策支持服务。', src: require('@/assets/TypicalCase/typeCase/dbly.png') },
        { title: '长安大学', content: '整合人事数据，规范信息标准和统计口径，消除信息孤岛。</br>规范业务流程，明确流程全责关系，提升业务质量。</br>全年系统集成，顺应智慧校园建设，实现资源共享。', src: require('@/assets/TypicalCase/typeCase/cadx.png') },

        { title: '青岛科技大学', content: '统一标准，搭建更规范化、精准化的全员信息库，通过业务办理实现数据自动更新。</br>提高效率，平台覆盖人力资源处、学院和全体职工，实现人力资源全场景应用。 </br>支撑决策，实时获取精确人事信息，多维度统计，为领导科学决策提供依据。', src: require('@/assets/TypicalCase/typeCase/qdkj.png') },
        { title: '浙江理工大学', content: '面向业务，根据人事处业务需求，优化和梳理业务流程，把大部分业务放到线上去完成，实现更全面、更深入、更高质量的人事业务管理。</br>面向服务，二级单位、教职工提供自助服务、信息查询和统计功能，提升教师体验。 </br>面向决策，建立多维度、多元化、多层级的人才评价与激励模型，为打造高水平师资队伍提供科学决策依据。', src: require('@/assets/TypicalCase/typeCase/zjlg.png') },
        { title: '西安建筑科技大学', content: '数字化系统规范业务流程，建立人力分析数据看板，实现全校人员规范管理。</br>借助先进技术实现考核规范化、业务化、全面化，提升考核与测评的有效性。</br>智能报表统计，数据上报自动生成，确保数据准确有效，解决统计之殇。', src: require('@/assets/TypicalCase/typeCase/xajzkjdx.png') },

        { title: '青岛农业大学', content: '便捷教职工服务，教职工基于平台查看个人信息，办理请假、申报等业务，提升管理参与度及满意度。</br>借助领导桌面，实现对人力资源的全局把控，快速准确地完成人力资源状态的静态结构分析和动态趋势分析。</br>搭建人力资源数据仓库，重构人事主数据体系，加深应用集成，开启移动时代，实现信息互联互通。', src: require('@/assets/TypicalCase/typeCase/qdnydx.png') },
        { title: '西湖大学', content: '实现人力资源规范管理，助力人事管理向数字化迈进。</br>通过考勤管理、报表统计的线上管理，加速人力资源的升级转型。 </br>多维度数据分析，实现数据深度应用，领导智慧决策。', src: require('@/assets/TypicalCase/typeCase/xh.png') },

        { title: '中国民航飞行学院', content: '灵活的单位绩效体系搭建，满足不同岗位考核需求。</br>可视化考核进度追踪及时催办，进展随时可控。 </br>多样性考核结果应用，奖金分配，激活组织，赋能创新。', src: require('@/assets/TypicalCase/typeCase/zgmhfx.png') },
        { title: '西安工程大学', content: '自定义薪酬结构建设，搭建线上薪酬体系，薪酬变更可追溯。</br>支持多元化薪资套账及核算规则管理，建设科学薪酬分配体系。 </br>根据套改文件制定套改规则，智能比对，全过程精准化管理。', src: require('@/assets/TypicalCase/typeCase/xagc.png') },

        { title: '广东机电职业技术学院', content: '自动生成组织架构图，信息穿透，层层展开至具体人员。</br>工资分套账管理，实现不同类别人员的分类管理。 </br>领导桌面直观了解学校人力资源状态，为科学决策提供数据支持。', src: require('@/assets/TypicalCase/typeCase/gdjd.png') },
        { title: '河源职业技术学院', content: '搭建薪酬体系，落地多套薪酬方案，执行学校薪酬战略。</br>高效精准算薪，灵活设置发薪审批流程，告别繁琐出错风险。 </br>薪酬数据多维度分析，薪酬报告一键生成，实现成本精细化管理。', src: require('@/assets/TypicalCase/typeCase/hyzy.png') }

      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/typicalCase/typeCase')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/TypicalCase/typeCase/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.motypeContainer{
  padding-top: 2.75rem;
}

.schoolCOntent{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.schoolItem{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.15);
  border-radius: 10px;
  margin-bottom: 20px;
}
.schoolItemImg{
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}
</style>
